<template>
  <div class="mt-3">
    <v-stepper class="setup-card ma-auto mb-3" v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step
            :complete="currentStep > 1"
            step="1"
        >
          Основное
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
            :complete="currentStep > 2"
            step="2"
        >
          Главная страница
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          SMTP
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-card class="ma-auto setup-card">
<!--      <v-card-subtitle>Осно сайта</v-card-subtitle>-->
      <v-card-text v-if="currentStep === 1">
        <v-row>
          <v-col class="my-auto" cols="3">Титул</v-col>
          <v-col><v-text-field placeholder="Введите титул"></v-text-field></v-col>

        </v-row>
        <v-row>
          <v-col class="my-auto" cols="3">Заголовок </v-col>
          <v-col><v-text-field placeholder="Введите загововок"></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col class="my-auto" cols="3">Краткий заголовок (для маленьких экранов)</v-col>
          <v-col><v-text-field placeholder="Введите краткий заголовок"></v-text-field></v-col>
        </v-row>
        <v-row>
          <v-col class="my-auto" cols="3">Личный кабинет</v-col>
          <v-col><v-checkbox></v-checkbox></v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-fade-transition>
          <v-btn v-if="currentStep > 1" text @click="currentStep--"><v-icon>mdi-chevron-left</v-icon> Назад </v-btn>
        </v-fade-transition>
        <transition name="fade">
          <v-btn v-if="currentStep < 3" text @click="nextStep()">Далее <v-icon>mdi-chevron-right</v-icon></v-btn>
          <v-btn v-else text @click="nextStep()">Начать использование <v-icon right>mdi-content-save</v-icon></v-btn>
        </transition>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Setup",
  data () {
    return {
      currentStep: 1,
      saving: false
    }
  },
  methods: {
    nextStep () {
      if(this.currentStep === 1) {
        this.currentStep = 2
      } else if (this.currentStep === 2) {
        this.currentStep = 3
      }
    }
  }
}
</script>

<style scoped>
  .setup-card {
    max-width: 1000px;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
</style>